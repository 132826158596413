import { Component, Renderer2 } from '@angular/core';
import { NavigationStart, Router,NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'arrellio-frontend';
  previousUrl: string;
  constructor(
    private router:Router,
    private renderer: Renderer2,
  ){
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // if(event.url == '/'){
        //   this.renderer.addClass(document.body, 'home');
        // }else{
        //   this.renderer.removeClass(document.body,'home');
        // }
        if (this.previousUrl) {
          let arr = this.previousUrl.split('/');
          arr.forEach(item=>{
            this.renderer.removeClass(document.body, item);
          });
        }
        let currentUrlSlug = event.url.split('?')[0].slice(1);
        if (currentUrlSlug) {
          let arr = currentUrlSlug.split('/');
          arr.forEach(item=>{
            this.renderer.addClass(document.body, item);
          });
        }
        this.previousUrl = currentUrlSlug;
      }
      if (event instanceof NavigationEnd) { 
        (<any>window).Intercom('update');
        console.log("intercom updated")
      }
    });
  }
}
