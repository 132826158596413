import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private transactionData: any;
  apiEndpoint:String = "";
  user:any;
  private loginSource = new BehaviorSubject<any>({});
  private transactionDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  user$ = this.loginSource.asObservable();

  // Show and hide Loader
  showLoader:boolean=true;
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();
  loader(value){this.loaderSubject.next( value ); }

  private regionSubject = new BehaviorSubject('');
  regionState = this.regionSubject.asObservable();
  changeRegion(value){
    localStorage.setItem('region',value);
    this.regionSubject.next(value);
  }


  private storeSubject = new Subject();
  storeState = this.storeSubject.asObservable();
  changeStore(store){
    if(store._id){
      localStorage.setItem('store',store._id);
    }else{
      localStorage.setItem('store','');
    }
    this.storeSubject.next(store);
  }

  private businessSubject = new Subject();
  businessState = this.businessSubject.asObservable();
  changeBusinessType(businessType){
    if(businessType){
      localStorage.setItem('businessType',businessType);
    }else{
      localStorage.setItem('businessType','');
    }
    this.businessSubject.next(businessType);
  }

  private shipSubject = new Subject();
  shipState = this.shipSubject.asObservable();
  changeShipType(shipType){
    if(shipType){
      localStorage.setItem('shipType',shipType);
    }else{
      localStorage.setItem('shipType','');
    }
    this.shipSubject.next(shipType);
  }


  enterpriseId:any;
  today=new Date();

  constructor(
    private http:HttpClient,
    private router:Router
  ) { 
    this.apiEndpoint = environment.API_END_POINT;
    if(localStorage.getItem('businessType')){
      this.businessSubject.next(localStorage.getItem('businessType'))
    }else{
      this.businessSubject.next('');
    }

    if(localStorage.getItem('shipType')){
      this.shipSubject.next(localStorage.getItem('shipType'))
    }else{
      this.shipSubject.next('');
    }

  }


  

  getData(url,param){
    return this.http.get<any>(this.apiEndpoint+url,{params:param});
  }

  postData(url,value){
    // console.log(value);
    Object.keys(value).forEach(key => {
      if(value[key] === undefined )
      delete value[key];
    });
    // console.log(value);
    return this.http.post<any>(this.apiEndpoint+url,value);
  }
  
  putData(url,value){
    return this.http.put<any>(this.apiEndpoint+url,value);
  }

  patchData(url,value){
    return this.http.patch<any>(this.apiEndpoint+url,value);
  }

  deleteData(url,param=null,body=null){
    return this.http.delete<any>(this.apiEndpoint+url,{params:param,body:body});
  }

  errorHandler(err){
    console.log(err);
  }

  swal(title,text,type){
    Swal.fire({
      title:title,
      text: text,
      icon: type,
      timer:2000
    });
  }

  updateLoginData(user){
    console.log('NEXT',user);
    this.user = user;
    this.loginSource.next(user);
  }

  logout(){
    Swal.fire({
      title: 'Are you sure?',
      // text: "You want to logout",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f85b5c',
      cancelButtonColor: '#9393934d',
      confirmButtonText: 'Logout'
    }).then((result) => {
      if (result.value) {
        this.clearTransactionData();
        this.logoutActions();
      }
    });
  }

  logoutActions(){
    this.router.navigate(['/login']);
    this.user = null;
    this.loginSource.next(null);
    this.enterpriseId = undefined;
    (<any>window).Intercom('shutdown');
    localStorage.clear();
    this.clearTransactionData();


  }

  parseLoginDetails(loginDetails){
    if(loginDetails && loginDetails.enterpriseId.distributor['system']){
      loginDetails.system = loginDetails.enterpriseId.distributor['system'];
      if(this.user){
        this.showLoader=false
        this.getTransactions()
      }
      switch(loginDetails.system){
        case 'OOGP': loginDetails.systemId = '5550'; break;
        case 'ABB': loginDetails.systemId = '5515'; break;
        case 'FAIT': loginDetails.systemId = '0002'; break;
        case 'E-DR': loginDetails.systemId = '0003'; break;
      }
      // loginDetails.isPasswordChanged = false;
    }
    return loginDetails;
  }


  setTransactionData(data: any): void {
    this.transactionDataSubject.next(data); // Broadcast the new data
  }

  // Getter for transaction data
  getTransactionData(){
    return this.transactionDataSubject.asObservable(); // Return the observable for subscribers
  }

  clearTransactionData() {
    this.transactionDataSubject.next(null);
  }
  getTransactions() {
    const now = new Date();
const year = now.getFullYear();
const month = now.getMonth() + 1; // Months are 0-indexed, so add 1

// Start Date: First day of the current month
const startDate = `${month.toString().padStart(2, '0')}-01-${year}`;

// End Date: Last day of the current month
const lastDay = new Date(year, month, 0).getDate(); // `0` gives the last day of the previous month
const endDate = `${month.toString().padStart(2, '0')}-${lastDay}-${year}`;

    this.showLoader=false
    let input: any = {};
    let storeId=localStorage.getItem('store')
    input.startDate=startDate;
    input.endDate=endDate
    input.skip=0;
    input.limit=10;
    console.log("storeId",storeId)
    if (this.user.type == "STORE") {
      input.storeId = this.user._id
    }
    if (storeId) {
      input.storeId = storeId
    }
    setTimeout(()=>{
      if(this.user?.enterpriseId?.stripeConnectId){
        this.showLoader = false;
        this.getData(`payment/transactions/${this.user?.enterpriseId?.stripeConnectId}`, input).subscribe(async (r) => {
          var newTransactions = await r.transactions;
          newTransactions = newTransactions.filter(t => t.DISPUTE_STATUS == 'needs_response' || t.DISPUTE_STATUS == 'warning_needs_response').length;
          console.log("newTransactions",newTransactions)
          this.setTransactionData(newTransactions)
        });
      }
      else if(!this.user?.enterpriseId?.stripeConnectId){
        this.setTransactionData([])
 }
    },8000)
   
    
 
  }
   
  

    
}